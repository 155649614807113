@media only screen and (max-width: 999px) {
    .mobile-version-container {
        gap: 20px;
        flex-direction: column;
        display: flex;
        flex-wrap: wrap;
    }

    .mobile-version-title {
        font-size: 13px;
    }

}