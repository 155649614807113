a {
    text-decoration: none;
}

a:hover {
    opacity: 0.7;
}

header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90px;
    margin-top: 30px;
    width: 100%;
    position: fixed;
    z-index: 99;
}

.nav-list {
    list-style: none;
    display: flex;
}

.nav-list li {
    letter-spacing: 2px;
    margin-left: 32px;
}




header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    visibility: hidden;
    opacity: 0;
    font-size: 1rem;
}


@media only screen and (max-width: 999px) {
    header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    header nav {
        position: fixed;
        z-index: 50;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-image: url("../../assets/bg.png");
        transition: 1s;
        transform: translateY(-100vh);
    }

    .nav-list {
        flex-direction: column;
        gap: 50px;
    }

    header .responsive_nav {
        transform: none;
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

}

