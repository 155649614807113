@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-image: url("./assets/bg-coin.png");
    background-color: #1e5799;
    background-repeat: no-repeat;
    background-clip: border-box;
    background-size: cover;
    background-position: center;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 999px) {
    body {
        background-position: center;
        padding: 10px;
    }

}

@font-face {
    font-family: "ArtGalleria";
    src: local('ArtGalleria'), url("./fonts/ArtGalleria.ttf"), format("truetype");
}