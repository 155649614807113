.background-influencer {
    background-image: url('../../assets/bg-coin.png');
    background-size: cover;
    background-position: center;


}

@media only screen and (max-width: 999px) {
    .mobile-version-main {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin-top: 10px;
        align-items: center;
    }

    .mobile-version-main-back {
        display: flex;
        justify-content: center;
    }

    .mobile-version-margin {
        margin-top: 30px;
    }
}