@media only screen and (max-width: 999px) {
    .mobile-footer {
        text-align: center;
        gap: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        
    }

}